<template>
  <div>
    <!-- 页头搜索 -->
    <div class="input">
      <div class="input-font" v-if="userRole">
        <div class="fontdiv">商户号</div>
        <el-input placeholder="请输入商户号" v-model="searchForm.mchNo" clearable size="medium"></el-input>
      </div>
      <div class="input-font" v-if="userRole">
        <div class="fontdiv">商户名称</div>
        <el-input placeholder="请输入商户名称" v-model="searchForm.mchName" clearable size="medium"></el-input>
      </div>
      <div class="input-font">
        <div class="fontdiv">应用ID</div>
        <el-input placeholder="请输入应用ID" v-model="searchForm.appId" clearable size="medium"></el-input>
      </div>
      <div class="input-font">
        <div class="fontdiv">订单号</div>
        <el-input placeholder="请输入订单号" v-model="searchForm.orderNo" clearable size="medium"></el-input>
      </div>
      <div class="input-font">
        <div class="fontdiv">支付接口代码</div>
        <el-input placeholder="请输入支付接口代码" v-model="searchForm.ifCode" clearable size="medium"></el-input>
      </div>
      <div class="input-font">
        <div class="fontdiv">支付状态</div>
        <el-select v-model="searchForm.state" placeholder="请选择应用支付状态" clearable class="select-width" size="medium">
          <el-option label="订单创建" :value="0" />
          <el-option label="等待支付" :value="1" />
          <el-option label="支付成功" :value="2" />
          <el-option label="支付失败" :value="3" />
          <el-option label="超时取消" :value="4" />
          <el-option label="订单退款" :value="5" />
        </el-select>
      </div>
      <el-button class="search-button button-height" type="primary" icon="el-icon-search" :loading="false"
        @click="getTableData()">搜索
      </el-button>
      <el-button class="button-height" plain icon="el-icon-refresh" @click="resetSearch()">重置</el-button>
    </div>

    <!-- 列表渲染 -->
    <div class="table">
      <el-table :data="tableData" ref="multipleTable" tooltip-effect="dark">
        <el-table-column prop="mchName" label="商户名称" min-width="170"></el-table-column>
        <el-table-column prop="orderNo" label="订单号" min-width="200">
          <template #default="scope">
            <div class="text-omit">
              <el-tag size="mini">支付</el-tag>
              {{ scope.row.payOrderId }}
            </div>
            <div class="text-omit">
              <el-tag size="mini" type="success">商户</el-tag>
              {{ scope.row.orderNo }}
            </div>
            <el-tooltip class="item" effect="dark" :content="scope.row.orderTradeNo" placement="top">
              <div class="text-omit">
                <el-tag size="mini" type="warning">渠道</el-tag>
                {{ scope.row.orderTradeNo }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="支付金额" min-width="100">
          <template #default="scope">
            ￥ {{ parseFloat(scope.row.amount / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column prop="ifCode" label="支付方式" min-width="100"></el-table-column>

        <el-table-column prop="state" label="支付状态" min-width="120">
          <template #default="scope">
            <el-tag v-if="scope.row.state === 0">订单创建</el-tag>
            <el-tag v-else-if="scope.row.state === 1">等待支付</el-tag>
            <el-tag v-else-if="scope.row.state === 2" type="success">支付成功</el-tag>
            <el-tag v-else-if="scope.row.state === 3" type="danger">支付失败</el-tag>
            <el-tag v-else-if="scope.row.state === 4" type="info">超时取消</el-tag>
            <el-tag v-else-if="scope.row.state === 5" type="warning">订单退款</el-tag>
            <el-tag v-else type="info">未知状态</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="notifyState" label="回调状态" min-width="120">
          <template #default="scope">
            <el-tag v-if="scope.row.notifyState === 0" type="warning">等待回调</el-tag>
            <el-tag v-else-if="scope.row.notifyState === 1">回调中</el-tag>
            <el-tag v-else-if="scope.row.notifyState === 2" type="success"> 回调成功</el-tag>
            <el-tag v-else-if="scope.row.notifyState === 3" type="danger">回调失败</el-tag>
            <el-tag v-else type="info">未知状态</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="refundState" label="退款状态" min-width="120">
          <template #default="scope">
            <a-tag v-if="scope.row.refundState === 0" color="blue">未发生实际退款</a-tag>
            <a-tag v-else-if="scope.row.refundState === 1" color="orange">退款中</a-tag>
            <a-tag v-else-if="scope.row.refundState === 2" color="green">退款成功</a-tag>
            <a-tag v-else-if="scope.row.refundState === 3" color="red">退款失败</a-tag>
            <a-tag v-else color="pink">未知状态</a-tag>
          </template>
        </el-table-column>
        <el-table-column prop="clientIp" label="客户端IP" min-width="120"></el-table-column>
        <el-table-column prop="mchNo" label="商户号" min-width="170"></el-table-column>
        <el-table-column prop="appId" label="应用ID" min-width="170"></el-table-column>
        <el-table-column prop="successTime" label="成功时间" min-width="160"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="160"></el-table-column>
        <el-table-column prop="updateTime" label="修改时间" min-width="160"></el-table-column>
        <!-- 浮动侧边栏 -->
        <el-table-column label="操作" min-width="130" fixed="right" align="center">
          <template #default="scope">
            <el-link @click="detailsRow(scope.row)" type="primary" :underline="false">详情
            </el-link>
            <el-link @click="refundSubmit(scope.row)" class="marginLeft" v-if="scope.row.state == 2" type="danger" :underline="false">退款
            </el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页器 -->
    <Pagination :total="total" :page="pageNumber" :size="pageSize" @getPage="getPage($event)"
      @getSize="getSize($event)">
    </Pagination>

    <!-- 详细信息  -->
    <el-drawer :wrapperClosable="true" :with-header="false" :visible.sync="detailsVisible" direction="rtl"
      :before-close="handleClose" size="60%" class="drawer">
      <div class="header">
        <a-steps :current="detailsData.state" type="navigation" class="header-steps" :disabled="true">
          <a-step title="订单创建" />
          <a-step title="等待支付" />
          <a-step title="订单超时" :status="detailsData.state == 4 ? 'finish' : 'finish'"  v-if="detailsData.state == 4" />
          <a-step title="支付成功" :status="detailsData.state == 2 ? 'finish' : 'finish'" v-else-if="detailsData.state != 3" />
          <a-step title="支付失败" v-else status="error" />
          <a-step title="订单退款" v-if="detailsData.state == 5" />
        </a-steps>
        <img class="drawer-close">
      </div>
      <div class="content">
        <a-descriptions title="订单详情" :column="2">
          <a-descriptions-item label="商户名称">
            {{ detailsData.mchName }}
          </a-descriptions-item>
          <a-descriptions-item label="商户号">
            {{ detailsData.mchNo }}
          </a-descriptions-item>
          <a-descriptions-item label="应用ID">
            {{ detailsData.appId }}
          </a-descriptions-item>
          <a-descriptions-item label="订单号">
            <div class="text-omit">
              <a-tag color="blue">支付</a-tag>
              {{ detailsData.payOrderId }}
            </div>
            <div class="text-omit">
              <a-tag color="green">商户</a-tag>
              {{ detailsData.orderNo }}
            </div>
            <div class="text-omit">
              <a-tag color="orange">渠道</a-tag>
              {{ detailsData.orderTradeNo }}
            </div>
          </a-descriptions-item>
          <a-descriptions-item label="支付金额">
            ￥ {{ parseFloat(detailsData.amount / 100).toFixed(2) }}
          </a-descriptions-item>
          <a-descriptions-item label="支付币种">
            {{ detailsData.currency }}
          </a-descriptions-item>
          <a-descriptions-item label="支付接口代码">
            {{ detailsData.ifCode }}
          </a-descriptions-item>
          <a-descriptions-item label="错误代码" v-if="detailsData.errCode != null">
            {{ detailsData.errCode }}
          </a-descriptions-item>
          <a-descriptions-item label="错误描述" v-if="detailsData.errMsg != null">
            {{ detailsData.errMsg }}
          </a-descriptions-item>
          <a-descriptions-item label="客户端IP">
            {{ detailsData.clientIp }}
          </a-descriptions-item>
          <a-descriptions-item label="渠道用户标识" v-if="detailsData.channelUser != null">
            {{ detailsData.channelUser != null ? detailsData.channelUser : '无' }}
          </a-descriptions-item>

          <a-descriptions-item label="支付状态">
            <a-tag v-if="detailsData.state === 0" color="blue">订单创建</a-tag>
            <a-tag v-else-if="detailsData.state === 1" color="blue">等待支付</a-tag>
            <a-tag v-else-if="detailsData.state === 2" color="green">支付成功</a-tag>
            <a-tag v-else-if="detailsData.state === 3" color="red">支付失败</a-tag>
            <a-tag v-else-if="detailsData.state === 4" >超时取消</a-tag>
            <a-tag v-else-if="detailsData.state === 5" color="orange">订单退款</a-tag>
            <a-tag v-else color="pink">未知状态</a-tag>
          </a-descriptions-item>
          <a-descriptions-item label="回调状态">
            <a-tag v-if="detailsData.notifyState === 0" color="orange">等待回调</a-tag>
            <a-tag v-else-if="detailsData.notifyState === 1" color="blue">回调中</a-tag>
            <a-tag v-else-if="detailsData.notifyState === 2" color="green"> 回调成功</a-tag>
            <a-tag v-else-if="detailsData.notifyState === 3" color="red">回调失败</a-tag>
            <a-tag v-else color="pink">未知状态</a-tag>
          </a-descriptions-item>
          <a-descriptions-item label="退款状态">
            <a-tag v-if="detailsData.refundState === 0" color="blue">未发生实际退款</a-tag>
            <a-tag v-else-if="detailsData.refundState === 1" color="orange">退款中</a-tag>
            <a-tag v-else-if="detailsData.refundState === 2" color="green">退款成功</a-tag>
            <a-tag v-else-if="detailsData.refundState === 3" color="red">退款失败</a-tag>
            <a-tag v-else color="pink">未知状态</a-tag>
          </a-descriptions-item>

          <a-descriptions-item label="支付渠道">
            <a-tag color="blue">{{ detailsData.ifCode }}</a-tag>
          </a-descriptions-item>
          <a-descriptions-item label="支付方式">
            <a-tag color="blue">{{ detailsData.wayCode }}</a-tag>
          </a-descriptions-item>
          <a-descriptions-item label="支付结果回调地址">
            {{ detailsData.notifyUrl }}
          </a-descriptions-item>
          <a-descriptions-item label="订单创建人标识">
            {{ detailsData.createBy }}
          </a-descriptions-item>
          <a-descriptions-item label="订单创建时间">
            {{ detailsData.createTime }}
          </a-descriptions-item>
          <a-descriptions-item label="订单完成时间" v-if="detailsData.state == 2">
            {{ detailsData.successTime }}
          </a-descriptions-item>
          <a-descriptions-item label="最后操作时间">
            {{ detailsData.updateTime }}
          </a-descriptions-item>
          <a-descriptions-item label="订单备注">
            {{ detailsData.remark }}
          </a-descriptions-item>
          <a-descriptions-item label="订单详情" class="textarea-omit">
            {{ detailsData.orderDetail }}
          </a-descriptions-item>
        </a-descriptions>
        <div>
          <div class="details-title">订单回调状态</div>
          <a-steps :current="detailsData.notifyState">
            <a-step title="等待回调" sub-title="Wait for callback" :description="'等待通知支付结果'" />
            <a-step title="回调中" sub-title="Being called back" :description="'正在通知支付结果'" />
            <a-step title="回调成功" v-if="detailsData.notifyState != 3"
              :status="detailsData.notifyState == 2 ? 'finish' : 'wait'" sub-title="Successful callback"
              description="支付结果通知正常" />
            <a-step title="回调失败" v-else sub-title="Callback failure" description="支付结果通知异常" status="error" />
          </a-steps>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  getPayOrderInfoData,
  refundOrder
} from "@/api/orderManagement/payOrderInfo";
import Pagination from "@/components/Pagination";

export default {
  name: "payOrderInfo",
  components: { Pagination },
  data() {
    return {
      //权限
      userRole: false,
      //页头搜索数据
      searchForm: {},
      //渲染列表数据
      tableData: [],
      //总条数
      total: 0,
      //当前页数
      pageNumber: 1,
      //当前每页条数
      pageSize: 5,

      //详情数据
      detailsData: {},
      //详情抽屉
      detailsVisible: false,
    };
  },

  mounted() {
    this.getTableData();
    this.getUserRole();
  },

  methods: {
    //分页查询
    async getTableData() {
      const data = { ...this.searchForm, pageNum: this.pageNumber, pageSize: this.pageSize };
      const res = await getPayOrderInfoData(data);
      console.log(res)
      this.tableData = res.data.rows
      this.total = parseInt(res.data.total)
    },

    //获取权限
    getUserRole() {
      const userRole = localStorage.getItem("userRole");
      console.log("sideNav页面：", userRole);
      if (userRole.includes("admin")) {
        this.userRole = true
      }
    },

    // 页头重置
    resetSearch() {
      this.searchForm = {}
    },

    //表单重置（添加/修改）
    resetForm() {
      this.detailsData = {}
    },

    //详情按钮
    detailsRow(row) {
      this.detailsData = row
      this.detailsVisible = true
    },

    // 退款
    async refundSubmit(row) {
      this.$confirm('是否确认退款?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const data = { "orderId": row.payOrderId }
        const res = await refundOrder(data)
        this.$message.success("订单退款成功,详情请查看退款页面")
        await this.getTableData();
      }).catch((e) => {
        if (e == 'cancel') {
          this.$message.info("已取消退款");
        }
      });
    },

    //详情抽屉---关闭
    closeDetails() {
      this.detailsVisible = false
      this.detailsData = {};
    },

    //抽屉关闭回调
    handleClose(done) {
      done()
    },

    //获取当前页数
    getPage(value) {
      this.pageNumber = value;
      this.getTableData();
    },

    //获取每页多少条
    getSize(value) {
      this.pageSize = value;
      this.getTableData();
    },
  }
}
</script>

<style scoped lang="less">
/* 搜索框样式 */
.input {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;

  .input-font {
    width: 400px;
    display: flex;
    margin-bottom: 10px;

    .fontdiv {
      color: #999;
      font-weight: 600;
      font-size: 15px;
      width: 150px;
      line-height: 36px;
      text-align: center;
    }

    .select-width {
      width: 400px;
    }
  }

  .button-height {
    height: 36px;
    margin-bottom: 10px;
    margin-left: 15px;
  }

}

/* 表格 */
.table {
  margin-top: 15px;

  .marginLeft {
    margin-left: 20px;
  }

  :deep .el-table .el-table__header-wrapper .el-table__header .el-table__cell {
    font-size: 14px;
    color: #999;
    font-weight: 600px;
    text-align: center;
    background-color: #f2f2f2;
  }

  :deep .el-table th.el-table__cell.is-leaf {
    background-color: #f2f2f2 !important;
  }

  :deep .el-table .el-table__body-wrapper .el-table__body .el-table__row .el-table__cell .cell {
    text-overflow: clip;
    text-align: center;
  }
}

/* 抽屉内容 */
.drawer-content {
  padding: 0 30px 70px 20px;

  .drawer-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    background: #fff;
    text-align: right;
    z-index: 1;
  }

  .marginRight {
    margin-right: 8px;
  }
}

// 文字省略
.text-omit {
  min-width: 190px;
  /* 设置容器宽度 */
  white-space: nowrap;
  /* 防止文本换行 */
  overflow: hidden;
  /* 文本溢出容器时隐藏 */
  text-overflow: ellipsis;
  /* 使用省略号表示被隐藏的文本 */
}

// 详细弹框
.drawer {
  .header {
    padding: 20px 30px;
    background-color: #fff;

    .header-steps {
      box-shadow: 0px -1px 0 0 #e8e8e8 inset;
    }
  }

  .content {
    padding: 5px 30px;
    overflow-y: auto;
    padding-bottom: 60px;
    background-color: #fff;

    :deep .ant-descriptions-row {
      display: flex;
    }

    :deep .ant-descriptions-item {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 50%;
    }

    .details-title {
      margin-bottom: 20px;
      margin-top: 40px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: bold;
      font-size: 16px;
      line-height: 1.5;
    }
  }
}

</style>
import service from "@/utils/request";


//分页查询所有
export const getPayOrderInfoData = (params) => service({
    url: '/PayOrder/queryPage',
    method: "get",
    params: params
});

// 订单退款
export const refundOrder = (params) => service({
    url: '/PayOrder/refund',
    method: "get",
    params: params
});


